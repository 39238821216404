var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-row',[(_vm.showEmptyCheckBox)?_c('div',{staticClass:"check-box-container"},[_c('show-empty-checkbox',{model:{value:(_vm.showEmpty),callback:function ($$v) {_vm.showEmpty=$$v},expression:"showEmpty"}})],1):_vm._e(),_c('v-col',[_c('gli-text-field',{attrs:{"disabled":_vm.disabled,"label":_vm.label + ' kezdete',"hint":'Formátum: ' + _vm.sample,"rules":[
            ..._vm.fieldRules,
            _vm.isValidFrom || 'Formátum: ' + _vm.sample,
            _vm.isValidRange || 'Hibás intervallum!'
          ],"clearable":""},on:{"blur":_vm.validateAndRefreshFrom,"keyup":_vm.keyupFrom,"click:clear":_vm.clearFrom},model:{value:(_vm.valueText.from),callback:function ($$v) {_vm.$set(_vm.valueText, "from", $$v)},expression:"valueText.from"}})],1),_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('span',{staticClass:"dash"},[_vm._v(" - ")])]),_c('v-col',{staticClass:"pl-0"},[_c('gli-text-field',{attrs:{"disabled":_vm.disabled,"label":_vm.label + ' vége',"hint":'Formátum: ' + _vm.sample,"rules":[
            ..._vm.fieldRules,
            _vm.isValidTo || 'Formátum: ' + _vm.sample,
            _vm.isValidRange || 'Hibás intervallum!'
          ],"clearable":""},on:{"blur":_vm.validateAndRefreshTo,"keyup":_vm.keyupTo,"click:clear":_vm.clearTo},scopedSlots:_vm._u([(!_vm.disabled)?{key:"append-outer",fn:function(){return [_c('v-icon',_vm._g({},on),[_vm._v("date_range")])]},proxy:true}:null],null,true),model:{value:(_vm.valueText.to),callback:function ($$v) {_vm.$set(_vm.valueText, "to", $$v)},expression:"valueText.to"}})],1)],1)]}}]),model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_c('v-date-picker',{attrs:{"max":_vm.maxDate,"min":_vm.minDate,"no-title":"","first-day-of-week":"1","range":""},on:{"change":_vm.datePickerChange},model:{value:(_vm.valueDatePicker),callback:function ($$v) {_vm.valueDatePicker=$$v},expression:"valueDatePicker"}},[[_c('gli-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.setThis('isoWeek')}}},[_vm._v("ez a hét")]),_c('gli-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.setThis('month')}}},[_vm._v("ez a hónap")]),_c('gli-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.setThis('year')}}},[_vm._v("ez az év")])]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }