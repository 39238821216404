<template>
  <v-menu
    v-model="showDatePicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-row>
        <div class="check-box-container" v-if="showEmptyCheckBox">
          <show-empty-checkbox v-model="showEmpty"></show-empty-checkbox>
        </div>
        <v-col>
          <gli-text-field
            :disabled="disabled"
            :label="label + ' kezdete'"
            :hint="'Formátum: ' + sample"
            v-model="valueText.from"
            @blur="validateAndRefreshFrom"
            @keyup="keyupFrom"
            @click:clear="clearFrom"
            :rules="[
              ...fieldRules,
              isValidFrom || 'Formátum: ' + sample,
              isValidRange || 'Hibás intervallum!'
            ]"
            clearable
          >
          </gli-text-field>
        </v-col>
        <div class="d-flex flex-column justify-center">
          <span class="dash"> - </span>
        </div>
        <v-col class="pl-0">
          <gli-text-field
            :disabled="disabled"
            :label="label + ' vége'"
            :hint="'Formátum: ' + sample"
            v-model="valueText.to"
            @blur="validateAndRefreshTo"
            @keyup="keyupTo"
            @click:clear="clearTo"
            :rules="[
              ...fieldRules,
              isValidTo || 'Formátum: ' + sample,
              isValidRange || 'Hibás intervallum!'
            ]"
            clearable
          >
            <template v-slot:append-outer v-if="!disabled">
              <v-icon v-on="on">date_range</v-icon>
            </template>
          </gli-text-field>
        </v-col>
      </v-row>
    </template>
    <v-date-picker
      :max="maxDate"
      :min="minDate"
      no-title
      first-day-of-week="1"
      range
      v-model="valueDatePicker"
      @change="datePickerChange"
    >
      <template>
        <gli-btn text small @click="setThis('isoWeek')">ez a hét</gli-btn>
        <gli-btn text small @click="setThis('month')">ez a hónap</gli-btn>
        <gli-btn text small @click="setThis('year')">ez az év</gli-btn>
      </template>
    </v-date-picker>
  </v-menu>
</template>

<style lang="scss" scoped>
.check-box-container {
  margin-left: 8px !important;
  padding-top: 22px !important;
  width: 20px;
}
.check-box-container ::v-deep .v-input__prepend-outer {
  margin-right: 5px !important;
}

.dash ::v-deep {
  width: 5px !important;
  margin-left: 2px !important;
  margin-right: 12px !important;
}
</style>

<script>
import moment, { ISO_8601 } from 'moment-mini';

import * as dateFormats from '../../../../shared/utils/date';

const DATEPICKER_FORMAT = 'YYYY-MM-DD';

export default {
  name: 'DateRangeField',
  props: {
    value: Object,
    label: String,
    maxDate: String,
    minDate: String,
    rules: Array,
    disabled: Boolean,
    showEmptyCheckBox: { type: Boolean, default: false },
    showEmptyCheckBoxVModel: { type: Boolean, default: false }
  },

  data() {
    return {
      dateFormats,
      clear: false,
      isValidFrom: true,
      isValidTo: true,
      isValidRange: true,
      showDatePicker: false,
      showEmpty: this.showEmptyCheckBoxVModel,
      valueISO: this.value,
      valueText: { from: null, to: null },
      valueDatePicker: [],
      fieldRules: []
    };
  },

  mounted() {
    this.refreshPickerValue();
    this.refreshDisplayText();
    if (this.rules) {
      this.fieldRules = this.rules;
    }
  },

  computed: {
    momentFromText() {
      return {
        from: this.valueText.from ? moment(this.valueText.from, dateFormats.formatDate) : null,
        to: this.valueText.to ? moment(this.valueText.to, dateFormats.formatDate) : null
      };
    },
    momentFromDatePicker() {
      return {
        from:
          this.valueDatePicker.length > 0
            ? moment(this.valueDatePicker[0], DATEPICKER_FORMAT)
            : null,
        to:
          this.valueDatePicker.length > 1
            ? moment(this.valueDatePicker[1], DATEPICKER_FORMAT)
            : null
      };
    },
    momentFromISO() {
      return {
        from: this.valueISO.from ? moment(this.valueISO.from, ISO_8601) : null,
        to: this.valueISO.to ? moment(this.valueISO.to, ISO_8601) : null
      };
    },
    sample() {
      return moment('2020-08-31', 'YYYY-MM-DD').format(dateFormats.formatDate);
    }
  },
  watch: {
    'valueText.from': {
      handler() {
        this.showEmpty = false;
      }
    },
    'valueText.to': {
      handler() {
        this.showEmpty = false;
      }
    },
    value() {
      if (this.value.from === null && this.value.to === null) {
        this.valueText = { from: '', to: '' };
        this.clear = true;
      }
    },
    showEmptyCheckBoxVModel() {
      this.showEmpty = this.showEmptyCheckBoxVModel;
    },
    showEmpty() {
      this.$emit('showEmpty', {
        showEmpty: this.showEmpty
      });
    },

    showDatePicker(show) {
      if (show) {
        this.refreshPickerValue();
      }
    },

    valueISO() {
      if (this.clear) {
        this.clear = false;
        return;
      }
      this.$emit('input', {
        ...this.valueISO
      });
    },

    valueText() {
      if (!this.momentFromText.from) {
        this.valueISO = { ...this.valueISO, from: null };
      } else if (this.momentFromText.from.isValid()) {
        this.valueISO = { ...this.valueISO, from: this.momentFromText.from.toISOString() };
      } else {
        this.valueISO = { ...this.valueISO, from: null };
      }

      if (!this.momentFromText.to) {
        this.valueISO = { ...this.valueISO, to: null };
      } else if (this.momentFromText.to.isValid()) {
        this.valueISO = { ...this.valueISO, to: this.momentFromText.to.toISOString() };
      } else {
        this.valueISO = { ...this.valueISO, to: null };
      }
    }
  },

  methods: {
    setThis(type) {
      this.valueISO = {
        from: moment().startOf(type).toISOString(),
        to: moment().endOf(type).toISOString()
      };
      this.refreshDisplayText();
      this.showDatePicker = false;
    },

    datePickerChange() {
      this.valueISO = {
        from:
          this.valueDatePicker.length > 0
            ? moment(this.valueDatePicker[0], DATEPICKER_FORMAT).toISOString()
            : null,
        to:
          this.valueDatePicker.length > 1
            ? moment(this.valueDatePicker[1], DATEPICKER_FORMAT).toISOString()
            : null
      };

      if (this.valueISO.from > this.valueISO.to) {
        this.valueISO = { to: this.valueISO.from, from: this.valueISO.to };
      }

      this.refreshDisplayText();
      if (this.valueDatePicker.length === 2) {
        this.showDatePicker = false;
      }
    },

    validateRange() {
      this.isValidRange = true;
      if (this.valueISO.from && this.valueISO.to && this.isValidTo && this.isValidFrom) {
        if (this.valueISO.to < this.valueISO.from) {
          this.isValidRange = false;
        }
      }
    },

    validateAndRefreshFrom() {
      if (!this.momentFromText.from) {
        this.valueISO = { ...this.valueISO, from: null };
        this.isValidFrom = true;
      } else if (this.momentFromText.from.isValid()) {
        this.valueISO = { ...this.valueISO, from: this.momentFromText.from.toISOString() };
        this.refreshDisplayText();
        this.isValidFrom = true;
      } else {
        this.isValidFrom = false;
      }

      this.validateRange();
    },
    validateAndRefreshTo() {
      if (!this.momentFromText.to) {
        this.valueISO = { ...this.valueISO, to: null };
        this.isValidTo = true;
      } else if (this.momentFromText.to.isValid()) {
        this.valueISO = { ...this.valueISO, to: this.momentFromText.to.toISOString() };
        this.refreshDisplayText();
        this.isValidTo = true;
      } else {
        this.isValidTo = false;
      }

      this.validateRange();
    },

    keyupFrom() {
      // if empty or contains a full date, then validate and emit
      if (!this.valueText.from || this.valueText.from.replace(/\D+/g, '').length === 8) {
        this.validateAndRefreshFrom();
      }
    },

    keyupTo() {
      // if empty or contains a full date, then validate and emit
      if (!this.valueText.to || this.valueText.to.replace(/\D+/g, '').length === 8) {
        this.validateAndRefreshTo();
      }
    },

    clearFrom() {
      this.valueText.from = '';
      this.validateAndRefreshFrom();
    },

    clearTo() {
      this.valueText.to = '';
      this.validateAndRefreshTo();
    },

    refreshDisplayText() {
      if (this.valueISO === null || this.valueISO === undefined) {
        this.valueText = { from: '', to: '' };
        return;
      }

      this.valueText = {
        from: this.momentFromISO.from ? this.momentFromISO.from.format(dateFormats.formatDate) : '',
        to: this.momentFromISO.to ? this.momentFromISO.to.format(dateFormats.formatDate) : ''
      };
    },
    refreshPickerValue() {
      this.valueDatePicker = [];
      if (!this.valueISO) {
        return;
      }

      if (this.valueISO.from) {
        this.valueDatePicker.push(this.momentFromISO.from.format(DATEPICKER_FORMAT));

        if (this.valueISO.to) {
          this.valueDatePicker.push(this.momentFromISO.to.format(DATEPICKER_FORMAT));
        }
      }
    },

    refresh(value) {
      this.valueISO = value;
      this.refreshDisplayText();
    }
  }
};
</script>
