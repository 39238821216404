import moment from 'moment-mini';
import { store } from '../store';
import { PACKING_ALGORITHMS } from './types';
import { getFormatDate, setTimeToStartOfDay } from '../../../shared/utils/date';

const preventKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

export function quantityInputFormatter(eventKey, number) {
  if (eventKey && preventKeys.indexOf(eventKey) !== -1) {
    return number;
  }
  if (number && number.length) {
    number = number.replace(/([^\d])/g, '');
    number = number.replace(/(?<=(.*\..*))\./g, '');
  }

  return number;
}

export const required = (value) =>
  (value !== null && value !== undefined && value !== '') || 'A mező kitöltése kötelező!';

export const notEmptyArray = (value) =>
  (value !== null && value !== undefined && Array.isArray(value) && value.length > 0) ||
  'A mező kitöltése kötelező!';

export const percent = (value) =>
  (value >= 0 && value <= 100) || '0 és 100 közé eső számot adjon meg!';

export const positiveNumber = (value) => {
  const num = Number(value);
  return num > 0 || 'Pozitív számot adjon meg!';
};

export const positiveInteger = (value) => {
  const num = Number(value);
  return (Number.isInteger(num) && num > 0) || 'Pozitív egész számot adjon meg!';
};

export const positiveIntegerOrUndefined = (value) => {
  const num = Number(value);
  return (Number.isInteger(num) && num > 0) || !value || 'Pozitív egész számot adjon meg!';
};

export const positiveIntegerOrZero = (value) => {
  const num = Number(value);
  return (Number.isInteger(num) && num >= 0) || 'Pozitív egész számot vagy nullát adjon meg!';
};

export const emailFormatRule = (value) => {
  /* eslint-disable no-useless-escape */
  return (
    /^$/.test(value) ||
    /^([a-zA-Z0-9\.!#$%&'*+\-\/=?^_`{|}~]{1,64}@[a-zA-Z0-9\.\-]+\.[a-zA-Z0-9]{2,4})$/.test(value) ||
    'Az email cím formátuma nem megfelelő!'
  );
};

export const validatePackingAlgorithmName = (value) => {
  return PACKING_ALGORITHMS.includes(value);
};

export const checkCalendar = (value) => {
  if (!store.state.calendar.loaded) {
    store.dispatch('calendar/refresh');
  }
  let workday = undefined;

  const calendarItem = store.state.calendar.calendar.find((item) =>
    new moment(item.date).isSame(value)
  );

  if (calendarItem) {
    workday = calendarItem.workday;
  } else {
    // from monday to friday
    const dayOfWeek = new Date(value).getDay();
    workday = dayOfWeek > 0 && dayOfWeek < 6;
  }

  return workday || 'A kiválasztott nap nem munkanap!';
};

export const timeString = (value) => {
  const isTimeString = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
  return isTimeString || 'A beírt érték nem megfelelő. Formátum: 8:00';
};

export const noSpaceString = (value) =>
  !value.includes(' ') || 'A szöveg nem tartalmazhat szóközt!';

export const mustNotBeGreaterThan = (value, maximum) => {
  const val = Number(value);
  const max = Number(maximum);
  return val <= max || `Kisebb számot adjon meg mint ${max}!`;
};

export const mustBeGreaterThan = (value, minimum) => {
  const val = Number(value);
  const min = Number(minimum);
  return val > min || `Nagyobb számot adjon meg mint ${min}!`;
};

export const mustNotBeEarlierThan = (value, minimumDate) => {
  const val = setTimeToStartOfDay(new Date(value));
  const min = setTimeToStartOfDay(new Date(minimumDate));
  return val >= min || `A választott dátum nem lehet korábbi, mint ${getFormatDate(minimumDate)}!`;
};

export const defaultVehicleCannotBeInactive = (isActive, isDefault) =>
  isDefault && !isActive ? 'Az inaktív jármű nem lehet alapértelmezett!' : true;

export const phoneNumber = (value) => {
  const isPhoneNumberOrEmpty = /^[0-9+\-()/ ]*$/.test(value);
  return (
    isPhoneNumberOrEmpty ||
    'A telefonszám csak számokat, szóközt és a {+, -, /, (, )} karaktereket tartalmazhatja!'
  );
};
