import moment from 'moment-mini';
import { DateTime } from 'luxon';

export const formatDate = 'YYYY.MM.DD';
export const formatTime = 'HH:mm';
export const formatDateTime = formatDate + ' ' + formatTime;
export const formatTimestamp = formatDate + ' ' + formatTime + ':ss';

/* eslint-disable max-len */
export const datePartialMatchingRegExp =
  /(^\d$|^\d{2}$|^\d{3}$|^\d{4}$|^\d{4}.$|^\d{4}.\d$|^\d{4}.\d{2}$|^\d{4}.\d{2}.$|^\d{4}.\d{2}.\d$|^\d{4}.\d{2}.\d{2}$)/g;

/**
 * Checks if given value can be turned into a valid date or not
 */
export function isValidDate(date: string | number | Date): boolean {
  return !isNaN(new Date(date).getTime());
}

export function isValidISODate(date: Date | string): boolean {
  // eslint-disable-next-line import/no-named-as-default-member
  return moment(date, moment.ISO_8601).isValid();
}

/**
 * Checks if given date is today or in the future
 */
export function isPresentOrFutureDate(date: string | number | Date): boolean {
  // if date is invalid no need to check if it's in the past or not
  return !isValidDate(date) || moment(new Date(date)).isSameOrAfter(moment().format('YYYY-MM-DD'));
}

export function getFormatDate(date: Date | string): string {
  if (!date) {
    return '';
  }
  return moment(date).format(formatDate);
}

export function getDisplayFormatDateTime(date: Date | string): string {
  if (!date) {
    return '';
  }
  return moment(date).format(formatDateTime);
}

export function getDisplayFormatTimestamp(date: Date | string): string {
  if (!date) {
    return '';
  }
  return moment(date).format(formatTimestamp);
}

/**
 * Compares the 2 given dates and
 * returns if they are the same (equal) timestamp or not.
 * Dates can be compared
 *   - as full timestamps or
 *   - as dates without time (default)
 */
export function isSameDate(
  date1: Date | string | undefined,
  date2: Date | string | undefined,
  onlyDate = true
): boolean {
  if (!date1 || !date2) {
    return false;
  }
  if (typeof date1 === 'string') {
    date1 = new Date(date1);
  }
  if (typeof date2 === 'string') {
    date2 = new Date(date2);
  }
  if (onlyDate) {
    date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
  }
  return new Date(date1).getTime() === new Date(date2).getTime();
}

/**
 *
 * @param date: can be a Date, string, number (suppose it is a millisec value), undefined, null
 * @returns the start of the given date's day
 */
export function setTimeToStartOfDay(
  date: Date | string | number | undefined | null
): Date | undefined {
  if (!date) {
    return undefined;
  }

  switch (typeof date) {
    case 'number':
      return DateTime.fromMillis(date).startOf('day').toJSDate();
    case 'string':
      return DateTime.fromJSDate(new Date(date)).startOf('day').toJSDate();
    case 'object':
      return DateTime.fromJSDate(date).startOf('day').toJSDate();
    default:
      return undefined;
  }
}

/**
 *
 * @param date: can be a Date, string, number (suppose it is a millisec value), undefined, null
 * @returns the end of the given date's day
 */
export function setTimeToEndOfDay(
  date: Date | string | number | undefined | null
): Date | undefined {
  if (!date) {
    return undefined;
  }

  switch (typeof date) {
    case 'number':
      return DateTime.fromMillis(date).endOf('day').toJSDate();
    case 'string':
      return DateTime.fromJSDate(new Date(date)).endOf('day').toJSDate();
    case 'object':
      return DateTime.fromJSDate(date).endOf('day').toJSDate();
    default:
      return undefined;
  }
}
